import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import monster from '../../media/godzilla.gif';
import './styles.css';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <Grid container className='fullSize' direction='column' justify='center' alignItems='center' id='ERR404' mt={10}>
      <Typography variant='h3'>{t('error.pageNotFound')}</Typography>
      <Typography variant='h5' align='center'>
        {t('error.pageNotFoundMessage')}
      </Typography>
      <Typography variant='h5' align='center' marginTop='20px'>
        <Button className='textLink' href='/'>
          {t('error.backToHome')}
        </Button>
      </Typography>
      <img id='monsterImg' src={monster} alt='Godzilla' />
    </Grid>
  );
};

export default PageNotFound;
