import React from 'react';
import { Typography, Container, Grid, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component='footer'
      sx={{
        bgcolor: '#191a23',
        color: 'white',
        py: 2,
      }}
    >
      <Container maxWidth='lg'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant='h6' gutterBottom>
              {t('footer.about.title')}
            </Typography>
            <Typography variant='body2' color='grey.400'>
              {t('footer.about.description')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='h6' gutterBottom>
              {t('footer.contact.title')}
            </Typography>
            <Typography variant='body2' color='grey.400'>
              support@eronware.com
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='h6' gutterBottom>
              {t('footer.legal.title')}
            </Typography>
            <Button color='inherit' href='/privacy-policy'>
              {t('footer.legal.privacyPolicy')}
            </Button>
            <Button color='inherit' href='/terms'>
              {t('footer.legal.termsOfService')}
            </Button>
          </Grid>
        </Grid>
        <Typography variant='body2' color='grey.400' align='center' sx={{ mt: 4 }}>
          {t('footer.copyright', { year: currentYear })}
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
