import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Button,
  ThemeProvider,
  createTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Grid,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SecurityIcon from '@mui/icons-material/Security';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BoltIcon from '@mui/icons-material/Bolt';
import { useTranslation } from 'react-i18next';

// Custom theme for consistent design
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    background: {
      default: '#f8f9fa',
      paper: '#fff',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#546e7a',
    },
  },
  typography: {
    h3: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '2rem',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '12px 24px',
          fontSize: '1.1rem',
        },
      },
    },
  },
});

const features = ['extension', 'priceTracking', 'profitCalc', 'fbaCalc', 'asinGrabber', 'priceScout'];

function PricingPage() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '80vh',
          py: 8,
        }}
      >
        <Container maxWidth='lg'>
          {/* Header Section */}
          <Box textAlign='center' mb={8} mt={4}>
            <Typography variant='h3' component='h1' gutterBottom>
              {t('pricing.title')}
            </Typography>
            <Typography variant='h6' color='text.secondary' gutterBottom>
              {t('pricing.subtitle')}
            </Typography>
          </Box>

          {/* Pricing Card */}
          <Box maxWidth='md' mx='auto'>
            <Paper
              elevation={3}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                border: '2px solid',
                borderColor: 'primary.main',
                p: 4,
              }}
            >
              {/* Popular Badge */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 35,
                  right: -42,
                  transform: 'rotate(45deg)',
                  bgcolor: 'primary.main',
                  color: 'white',
                  px: 4,
                  py: 0.5,
                }}
              >
                <Typography variant='body2'>{t('pricing.limitedOffer')}</Typography>
              </Box>

              {/* Price Header */}
              <Box textAlign='center' mb={4}>
                <Typography variant='h4' component='div' color='primary' gutterBottom fontWeight='bold'>
                  {t('pricing.freePlan')}
                </Typography>
                <Box display='flex' justifyContent='center' alignItems='baseline' mb={2}>
                  <Typography variant='h3' component='span' fontWeight='bold'>
                    $0
                  </Typography>
                  <Typography variant='h6' color='text.secondary' ml={1}>
                    {t('pricing.perMonth')}
                  </Typography>
                </Box>
                <Typography color='text.secondary'>{t('pricing.untilDate')}</Typography>
              </Box>

              {/* Features List */}
              <List sx={{ mb: 4 }}>
                {features.map((feature, index) => (
                  <ListItem key={index} sx={{ py: 1 }}>
                    <ListItemIcon>
                      <CheckCircleIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary={t(`pricing.features.${feature}`)} />
                  </ListItem>
                ))}
              </List>

              {/* Trust Indicators */}
              <Box textAlign='center'>
                <Typography color='text.secondary' gutterBottom>
                  {t('pricing.trust.message')}
                </Typography>
                <Grid container spacing={2} justifyContent='center'>
                  <Grid item>
                    <Chip icon={<SecurityIcon />} label={t('pricing.trust.secure')} variant='outlined' />
                  </Grid>
                  <Grid item>
                    <Chip icon={<SupportAgentIcon />} label={t('pricing.trust.support')} variant='outlined' />
                  </Grid>
                  <Grid item>
                    <Chip icon={<BoltIcon />} label={t('pricing.trust.instant')} variant='outlined' />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default PricingPage;
