import './App.css';
import SignIn from './screens/login/SignIn';
import SignUp from './screens/signup/SignUp';
import Home from './screens/home/Home';
import ForgotPassword from './screens/forgot-password/ForgotPassword';
import ResetPassword from './screens/reset-password/ResetPassword';
import PageNotFound from './screens/page-not-found/PageNotFound';
import Blog from './screens/blog/Blog';
import Pricing from './screens/pricing/Pricing';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToken, setRefresh, initializeAuth } from './features/auth/authSlice';
import Cookies from 'universal-cookie';
import NavBar from './components/navBar/Navbar';
import PrivacyPolicy from './screens/privacy-policy/PrivacyPolicy';
import TermsOfService from './screens/terms-of-service/TermsOfService';
import Footer from './components/footer/Footer';
import { checkRefreshToken } from './features/auth/authActions';
import { logout } from './features/auth/authSlice';
import './i18n/i18n';
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const { token, refresh } = useSelector((state) => state.auth);
  const location = useLocation();

  if (!token || !refresh) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, refresh } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isRefreshTokenValid, setIsRefreshTokenValid] = useState(false);

  // Check if current route is auth page
  const authPages = ['/login', '/register', '/forgot-password', '/reset-password'];
  const isAuthPage = authPages.includes(location.pathname);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const cookies = new Cookies();
      const token = cookies.get('token');
      const refresh = cookies.get('refresh');

      try {
        if (token && refresh) {
          const result = await dispatch(checkRefreshToken({ refreshToken: refresh }));
          setIsRefreshTokenValid(result?.payload);

          if (result?.payload?.status) {
            dispatch(setToken(token));
            dispatch(setRefresh(refresh));
            dispatch(initializeAuth());
          } else {
            dispatch(logout());
          }
        }
      } catch (error) {
        setIsRefreshTokenValid(false);
        dispatch(logout());
      }
    };

    checkTokenValidity();
  }, [dispatch]);

  const protectedRoutes = ['/dashboard', '/profile', '/settings'];

  useEffect(() => {
    if (!token && !refresh && protectedRoutes.includes(location.pathname)) {
      navigate('/login');
    }
  }, [navigate, token, refresh, location.pathname]);

  return (
    <Suspense fallback={<CircularProgress />}>
      <div>
        {!isAuthPage && <NavBar />}
        <main className='container content'>
          <Routes>
            {/* Public Routes */}
            <Route path='/' element={<Home />} />
            <Route path='/register' element={<SignUp />} />
            <Route path='/login' element={<SignIn />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms' element={<TermsOfService />} />

            {/* Protected Routes */}
            {protectedRoutes.map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <ProtectedRoute>
                    {path === '/dashboard' && <div>Dashboard Content</div>}
                    {path === '/profile' && <div>Profile Content</div>}
                    {path === '/settings' && <div>Settings Content</div>}
                  </ProtectedRoute>
                }
              />
            ))}

            <Route path='/*' element={<PageNotFound />} />
          </Routes>
        </main>
        {!isAuthPage && <Footer />}
      </div>
    </Suspense>
  );
}

export default App;
