import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Box, IconButton, Tooltip } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2563eb',
      light: '#60a5fa',
    },
    background: {
      default: '#f8fafc',
      paper: '#ffffff',
    },
    text: {
      primary: '#1e293b',
      secondary: '#64748b',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Arial", sans-serif',
    h3: {
      fontWeight: 700,
      fontSize: '2.5rem',
      letterSpacing: '-0.02em',
      marginBottom: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.1rem',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7,
    },
  },
  shape: {
    borderRadius: 12,
  },
});

const tipColors = ['#3b82f6', '#14b8a6', '#f59e0b', '#6366f1', '#ec4899', '#8b5cf6', '#10b981', '#f43f5e'];

const styles = {
  root: {
    bgcolor: 'background.default',
    minHeight: '80vh',
    py: 8,
    mt: 4,
  },
  header: {
    textAlign: 'center',
    mb: 6,
  },
  card: {
    height: '100%',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    mb: 2,
  },
  icon: {
    p: 1,
    borderRadius: 2,
    color: '#fff',
  },
};

function Blog() {
  const { t } = useTranslation();

  const tips = [
    {
      key: 'research',
      color: tipColors[0],
    },
    {
      key: 'seo',
      color: tipColors[1],
    },
    {
      key: 'photos',
      color: tipColors[2],
    },
    {
      key: 'seasonal',
      color: tipColors[3],
    },
    {
      key: 'social',
      color: tipColors[4],
    },
    {
      key: 'pricing',
      color: tipColors[5],
    },
    {
      key: 'service',
      color: tipColors[6],
    },
    {
      key: 'fba',
      color: tipColors[7],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles.root}>
        <Container maxWidth='lg'>
          <Box sx={styles.header}>
            <Typography variant='h3' color='text.primary'>
              {t('blog.pageTitle')}
            </Typography>
            <Typography variant='body1' color='text.secondary' sx={{ maxWidth: 600, mx: 'auto' }}>
              {t('blog.pageSubtitle')}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {tips.map((tip, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={styles.card}>
                  <CardContent>
                    <Box sx={styles.iconWrapper}>
                      <IconButton
                        sx={{
                          ...styles.icon,
                          bgcolor: tip.color,
                        }}
                      >
                        <LightbulbIcon />
                      </IconButton>
                    </Box>
                    <Typography variant='h6' gutterBottom>
                      {t(`blog.tips.${tip.key}.title`)}
                    </Typography>
                    <Typography variant='body1' color='text.secondary'>
                      {t(`blog.tips.${tip.key}.description`)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Blog;
