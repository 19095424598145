import React, { useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  Assessment as AssessmentIcon,
  Code as CodeIcon,
  Support as SupportIcon,
  Speed as SpeedIcon,
  Security as SecurityIcon,
  CloudDone as CloudDoneIcon,
} from '@mui/icons-material';
import backgroundImg from '../../media/site-background.webp';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const { t } = useTranslation();

  const handleContactDialogOpen = () => {
    setOpenContactDialog(true);
  };

  const handleContactDialogClose = () => {
    setOpenContactDialog(false);
  };

  const features = [
    {
      icon: <AssessmentIcon sx={{ fontSize: 48, color: 'primary.main' }} />,
      title: t('home.solutions.features.arbitrage.title'),
      description: t('home.solutions.features.arbitrage.description'),
      buttonText: t('home.solutions.features.arbitrage.button'),
      buttonLink: 'https://chromewebstore.google.com/detail/eronware-amazon-arbitrage/ehmfdpppjjaibmabjoinbfmlnncjahkh',
    },
    {
      icon: <CloudDoneIcon sx={{ fontSize: 48, color: 'primary.main' }} />,
      title: t('home.solutions.features.priceScout.title'),
      description: t('home.solutions.features.priceScout.description'),
      buttonText: t('home.solutions.features.priceScout.button'),
      buttonLink: 'https://chromewebstore.google.com/detail/eronware-price-scout/hehbekonbigpihcmpmgfdpkpjnfkechi',
    },
    {
      icon: <CodeIcon sx={{ fontSize: 48, color: 'primary.main' }} />,
      title: t('home.solutions.features.custom.title'),
      description: t('home.solutions.features.custom.description'),
      buttonText: t('home.solutions.features.custom.button'),
      onClick: handleContactDialogOpen,
    },
  ];

  const services = [
    {
      icon: <SpeedIcon />,
      title: t('home.whyChooseUs.services.integration.title'),
      description: t('home.whyChooseUs.services.integration.description'),
    },
    {
      icon: <SecurityIcon />,
      title: t('home.whyChooseUs.services.security.title'),
      description: t('home.whyChooseUs.services.security.description'),
    },
    {
      icon: <SupportIcon />,
      title: t('home.whyChooseUs.services.support.title'),
      description: t('home.whyChooseUs.services.support.description'),
    },
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: '#191a23',
          color: 'white',
          pt: { xs: 4, sm: 6, md: 12 },
          pb: { xs: 8, sm: 12, md: 16 },
          minHeight: { xs: '40vh', md: 'auto' },
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth='lg'>
          <Grid container spacing={4} alignItems='center'>
            <Grid item xs={12} md={6}>
              <Typography
                component='h1'
                variant='h2'
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  fontSize: { xs: '2.25rem', sm: '2.5rem', md: '3.75rem' },
                }}
              >
                {t('home.hero.title')}
              </Typography>
              <Typography variant='h5' color='grey.300' sx={{ mb: 4 }}>
                {t('home.hero.subtitle')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth='lg' sx={{ py: 8 }}>
        <Typography component='h2' variant='h3' align='center' gutterBottom sx={{ mb: 6, fontWeight: 700 }}>
          {t('home.solutions.title')}
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: 6,
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center', p: 4 }}>
                  <Box sx={{ mb: 3 }}>{feature.icon}</Box>
                  <Typography gutterBottom variant='h5' component='h3' sx={{ fontWeight: 600 }}>
                    {feature.title}
                  </Typography>
                  <Typography color='text.secondary' paragraph>
                    {feature.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ p: 3, pt: 0 }}>
                  <Button
                    fullWidth
                    variant='contained'
                    size='large'
                    href={feature.buttonLink}
                    target={feature.buttonLink ? '_blank' : undefined}
                    onClick={feature.onClick}
                  >
                    {feature.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Services Section */}
      <Box sx={{ bgcolor: '#eef2f5', py: 8 }}>
        <Container maxWidth='lg'>
          <Typography variant='h4' align='center' sx={{ mb: 6, fontWeight: 600 }}>
            {t('home.whyChooseUs.title')}
          </Typography>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 4,
                    boxShadow: 2,
                    borderRadius: 2,
                    bgcolor: 'white',
                    transition: 'transform 0.3s ease',
                    '&:hover': { transform: 'scale(1.05)' },
                  }}
                >
                  <Box sx={{ mb: 3 }}>{service.icon}</Box>
                  <Typography variant='h6' sx={{ fontWeight: 600 }}>
                    {service.title}
                  </Typography>
                  <Typography>{service.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Contact Dialog */}
      <Dialog open={openContactDialog} onClose={handleContactDialogClose} maxWidth='sm' fullWidth>
        <DialogTitle>{t('home.contact.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('home.contact.message')}</DialogContentText>
          <Typography variant='body1' sx={{ mt: 2, fontWeight: 500 }}>
            {t('home.contact.email')}: support@eronware.com
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContactDialogClose}>{t('home.contact.close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
