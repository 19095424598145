import React from 'react';
import { Container, Typography, Box, Paper, Grid, List, ListItem, ListItemText, Divider, Link } from '@mui/material';
import {
  Gavel as GavelIcon,
  Info as InfoIcon,
  Security as SecurityIcon,
  Warning as WarningIcon,
  Update as UpdateIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t } = useTranslation();

  const sections = [
    {
      icon: <InfoIcon color='primary' />,
      title: `1. ${t('legal.terms.sections.intro.title')}`,
      content: t('legal.terms.sections.intro.content'),
    },
    {
      icon: <GavelIcon color='primary' />,
      title: `2. ${t('legal.terms.sections.acceptance.title')}`,
      content: t('legal.terms.sections.acceptance.content'),
    },
    {
      icon: <SecurityIcon color='primary' />,
      title: `3. ${t('legal.terms.sections.responsibilities.title')}`,
      content: t('legal.terms.sections.responsibilities.content'),
      list: t('legal.terms.sections.responsibilities.list', { returnObjects: true }),
    },
    {
      icon: <WarningIcon color='primary' />,
      title: `4. ${t('legal.terms.sections.limitations.title')}`,
      content: t('legal.terms.sections.limitations.content'),
    },
    {
      icon: <UpdateIcon color='primary' />,
      title: `5. ${t('legal.terms.sections.changes.title')}`,
      content: t('legal.terms.sections.changes.content'),
    },
  ];

  return (
    <Box sx={{ bgcolor: '#f9f9f9', py: 8, mt: 4 }}>
      <Container maxWidth='md'>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
          <Typography variant='h3' align='center' gutterBottom sx={{ fontWeight: 700, mb: 3 }}>
            {t('legal.terms.title')}
          </Typography>
          <Typography variant='subtitle1' align='center' sx={{ color: 'grey.600', mb: 6 }}>
            {t('legal.terms.lastUpdated')}: 16/07/2024
          </Typography>
          <Divider sx={{ mb: 4 }} />

          {sections.map((section, index) => (
            <Box key={index} sx={{ mb: 6 }}>
              <Grid container spacing={2} alignItems='center' sx={{ mb: 2 }}>
                <Grid item>{section.icon}</Grid>
                <Grid item>
                  <Typography variant='h5' sx={{ fontWeight: 600 }}>
                    {section.title}
                  </Typography>
                </Grid>
              </Grid>
              <Typography paragraph>{section.content}</Typography>
              {section.list && (
                <List>
                  {section.list.map((item, i) => (
                    <ListItem key={i} disablePadding>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          ))}

          <Divider sx={{ mt: 6, mb: 4 }} />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h5' sx={{ mb: 2, fontWeight: 600 }}>
              {t('legal.terms.contact')}
            </Typography>
            <Typography paragraph>{t('legal.terms.contactText')}</Typography>
            <Link href='mailto:support@eronware.com' sx={{ fontSize: '1.2rem', color: 'primary.main' }}>
              support@eronware.com
            </Link>
          </Box>
          <Divider sx={{ mt: 6, mb: 4 }} />
          <Typography variant='body2' align='center' sx={{ color: 'grey.600' }}>
            © {new Date().getFullYear()} Eronware. {t('legal.terms.allRightsReserved')}
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default TermsOfService;
