import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { registerUser } from '../../features/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

function Copyright(props) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://eronware.com/'>
        Eronware
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const { loading, userInfo, error, success } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (success) navigate('/login');
    if (userInfo) navigate('/user-profile');
  }, [navigate, userInfo, success]);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'bottom-center',
      });
    }
  }, [error]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!termsAccepted) {
      setShowTermsError(true);
      return;
    }

    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());
    data.email = data.email.toLowerCase();
    dispatch(registerUser(data));
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
    if (event.target.checked) {
      setShowTermsError(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {t('auth.signupButton')}
          </Typography>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField required fullWidth id='email' label={t('auth.email')} name='email' autoComplete='email' />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name='password'
                  label={t('auth.password')}
                  type='password'
                  id='password'
                  autoComplete='new-password'
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl error={showTermsError} component='fieldset'>
                  <FormControlLabel
                    control={<Checkbox checked={termsAccepted} onChange={handleTermsChange} color='primary' />}
                    label={
                      <Typography variant='body2'>
                        {t('auth.termsAgreement')}{' '}
                        <Link href='/terms' target='_blank'>
                          {t('auth.termsOfService')}
                        </Link>{' '}
                        {t('auth.and')}{' '}
                        <Link href='/privacy-policy' target='_blank'>
                          {t('auth.privacyPolicy')}
                        </Link>
                      </Typography>
                    }
                  />
                  {showTermsError && <FormHelperText>{t('auth.termsError')}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              {t('auth.signupButton')}
            </Button>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link href='/login' variant='body2'>
                  {t('auth.haveAccount')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}
